<template>
    <VuePureLightbox class="lightbox">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <section class="send-reset has-text-centered">
        <div class="container">
            <table class="reg-form has-text-centered">
            <tr>
                <td class="form-header">
                    <p class="org-description is-size-5">Reset your password via e-mail</p>
                </td>
            </tr>
            <tr>
                <td class="form-content has-text-centered">
                    <ValidationProvider rules="required|email" name="Email" v-slot="{ errors, valid }">
                        <b-field label="Confirm Your Email Address" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
                            <b-input type="email" maxlength="50" onkeyup='this.value = this.value.toLowerCase();' v-model="user.email" v-on:keyup.native.enter="handleSubmit(send_reset)">
                            </b-input>
                        </b-field>
                    </ValidationProvider>
                    <button class="button submit is-primary is-outlined" @click="handleSubmit(send_reset)">
                        <b-icon pack="fa" icon="envelope"></b-icon>
                        <span>Send</span>
                    </button> 
                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                    <div class="form-group">
                        <div v-if="message" class="is-danger" role="alert">{{message}}</div>
                    </div>  
                </td>
            </tr>
            </table>
        </div>
    </section>
    </ValidationObserver>
    </VuePureLightbox>
</template>

<script>
import User from '../models/user';
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: 'send-reset',
  components: {
            ValidationObserver,
            ValidationProvider,
  },
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: '',
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    if (this.loggedIn) {
      console.log(this.next);
      this.$router.push('/dashboard/profile');
    }
  },
  methods: {
    send_reset() {
        requestAnimationFrame(() => {
            this.load();
            this.user.api = process.env.VUE_APP_API;
            console.log("Attempting to send password reset for: " + this.user.email)
            this.loading = true;

            if (this.user.email && this.user.api) {
                this.$store.dispatch('auth/send_reset', this.user).then(
                (response) => {
                        console.log(response.status);
                        if (response.status == true) {
                            this.message = "Password reset e-mail sent!";
                            this.$router.replace({ path: "/send/resetdone/1" });
                        } else {
                            this.message = "Server error...";
                            this.user.password="";
                            this.$router.replace({ path: "/send/resetdone/2" });
                        }
                    },
                    error => {
                        this.loading = false;
                        this.message =
                        (error.response && error.response.data) ||
                        error.message ||
                        error.toString();
                        this.$router.replace({ path: "/send/resetdone/3" });
                    }
                );
            }
        });
    },
    load() {
        let loader = this.$loading.show({
            container: this.fullPage,
            onCancel: this.onCancel,
            active: this.isLoading
        });
        setTimeout(() => {
            loader.hide()
        },1000)
    },    
  }
};
</script>

<style lang="scss">
    $main-color: #aac734;
    .lightbox {
        width: 100%; height: 100%;
        background: rgba(0, 0, 0, .4);
    }
    .send-reset {
        padding-top: 0px; // 55px;
        padding-bottom: 100px;
        width: 100%;
    }
    .container {
        width: 100%;
        justify-content: center;
    }
    .submit {
        margin-top: 30px;
        text-align: center;
    }
    .reg-form {
        width: 100%;
        min-width: 450px;
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px 2px 30px rgba(0,0,0,0.3);
        margin-left: auto;
        margin-right: auto;
        margin-top: 0px; // 40px;
    }
    .form-header {
        font-weight: bold;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        background: $main-color;
    }
    .form-content {
        background: #F5F5F5;
        padding: 30px;
        padding-left: 10%;
        padding-right: 10%;
    }
    .form-content .label {
        font-size: 0.78rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 400;
    }
</style>